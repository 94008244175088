<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(20, 'Multiselection')"
		header-class="header-class-modal-doc-package"
		size="lg"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@hidden="emitEventClose"
		@close="emitEventClose"
	>
		<div>
			<b-row>
				<b-col>
					<div class="fs-12 text-color-rhapsody-in-blue">
						{{
							`${FormMSG(59, 'You just selected the')} ${totalDataNumberSelected} ${FormMSG(
								60,
								'records displayed in the list, while here are in total'
							)} ${totalDataNumber} ${FormMSG(96, 'records.')}`
						}}
					</div>
					<div class="fs-12 text-color-rhapsody-in-blue mt-3">
						{{ `${FormMSG(101, 'Do you want to extend your selection to the')} ${totalDataNumber} ${FormMSG(152, 'records ?')}` }}
					</div>
				</b-col>
			</b-row>
		</div>
		<template #modal-footer="{ cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button variant="custom-outline-gray" class="mr-2" @click="handleClickCancelSelection(cancel)">
					{{ FormMSG(587, 'Cancel') }}
				</b-button>
				<b-button variant="outline-primary" class="mr-2" @click="handleClickCancel(cancel)">
					{{ FormMSG(46, 'Keep current selection') }}
				</b-button>
				<b-button variant="primary" @click="handleClickOk(cancel)">
					{{ okCaption }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'MultiSelectionConfirmDialog',

	mixins: [languageMessages],

	props: {
		open: { type: Boolean, default: false },
		totalDataNumber: { type: Number, default: 0 },
		totalDataNumberSelected: { type: Number, default: 0 }
	},

	data() {
		return {};
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},

		okCaption() {
			return `${this.FormMSG(49, 'Select all')} ${this.totalDataNumber} ${this.FormMSG(50, 'records')}`;
		}
	},

	methods: {
		handleClickCancelSelection(actionClose) {
			this.$emit('multi-selection-confirm-dialog:cancel-selection');
			actionClose();
		},
		handleClickCancel(actionClose) {
			this.$emit('multi-selection-confirm-dialog:keep');
			actionClose();
		},
		handleClickOk(actionClose) {
			this.$emit('multi-selection-confirm-dialog:select-all');
			actionClose();
		},
		emitEventClose() {
			this.$emit('multi-selection-confirm-dialog:close');
		}
	}
};
</script>
