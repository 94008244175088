<template>
	<error-container :error="error">
		<transition name="slide">
			<div style="position: relative">
				<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
					<div class="form">
						<b-row>
							<b-col>
								<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(18, 'Job request list') }}</h1>
							</b-col>
						</b-row>
						<div ref="containerJobRequestList">
							<b-row class="mt-3">
								<b-col class="p-0">
									<b-card no-body>
										<b-card-text>
											<b-row v-if="stateError.show">
												<b-col>
													<b-alert show variant="danger" dismissible @dismissed="handleDismissedError">
														{{ stateError.msg }}
													</b-alert>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<filter-budget-advanced
														hide-expense-details-section
														hide-supplier-section
														hide-entry-detail-section
														show-job-request-entry-detail-section
														show-user-selection-only
														hide-button-export
														hide-button-refresh-data
														show-user-in-section-when
														hide-person-section
														:label-expense-details="FormMSG(148, 'Expense details')"
														:filter-type="FILTER_TYPE.JOB_REQUEST"
														@filter-budget-advanced:have-filter="handlerFilterBudgetAdvancedHaveFilter"
													/>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<b-col md="7">
														<div class="d-flex justify-content-between">
															<div class="row">
																<div class="text-color-australien fs-16 fw-600">
																	<div class="d-flex">
																		<div class="ml-2 pt-1">
																			0 - {{ pagination.offset }} {{ FormMSG(519, 'on') }} {{ totalRecord }}
																			{{ FormMSG(520, 'records') }}
																		</div>
																	</div>
																</div>
																<b-button
																	class="ml-3"
																	size="sm"
																	variant="outline-primary"
																	:disabled="pagination.offset === totalRecord"
																	@click="handleClickLoadMore"
																>
																	{{ FormMSG(856, 'Load more') }}
																</b-button>
															</div>
														</div>
													</b-col>
												</b-col>
											</b-row>
											<b-row class="mt-3">
												<b-col id="containerJobRequestTable" ref="containerJobRequestTable">
													<b-table
														v-if="$screen.width >= 992"
														selected-variant="primary"
														hover
														selectable
														select-mode="single"
														responsive="sm"
														sticky-header="700px"
														:items="jobRequestList"
														style="text-align: left"
														:fields="jobRequestFields"
														bordered
														striped
														small
														head-variant="dark"
														:empty-text="FormMSG(250, 'No data found')"
														show-empty
														tbody-tr-class="p-2"
														ref="table-job-request"
													>
														<template #head(actionSelected)="{}">
															<div style="height: 55px">
																<b-form-checkbox
																	id="selectAllJobRequestList"
																	v-if="jobRequestList.length > 0"
																	v-model="selectAll"
																	size="lg"
																	@change="handleInputSelectAll"
																/>
															</div>
														</template>
														<template #head(id)="{ label }">
															<div style="height: 55px">
																{{ label }}
															</div>
														</template>
														<template #head(requestDate)="{ label }">
															<div style="height: 55px">
																{{ label }}
															</div>
														</template>
														<template #head(status)="{ label }">
															<div style="height: 55px">
																{{ label }}
															</div>
														</template>
														<template #head(fullName)="{ label }">
															<b-form-group class="mb-0">
																<template #label>
																	<div class="text-white">
																		{{ label }}
																	</div>
																</template>
																<b-input-group>
																	<b-form-input
																		v-model="filterBy.fullNameKeyWord"
																		:placeholder="FormMSG(1899, 'Type to search')"
																		@keyup.enter="handleChangeHeaderFilter"
																		v-b-tooltip.hover
																		:title="FormMSG(2154, 'Press enter for execute search')"
																	/>
																	<b-input-group-append class="cursor-pointer">
																		<b-input-group-text>
																			<component
																				:is="getLucideIcon('Search')"
																				color="#ffffff"
																				:size="16"
																				:stroke-width="2.5"
																				v-if="filterBy.fullNameKeyWord.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#FFFFFF"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="handleResetFilterBy('fullNameKeyWord')"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-form-group>
														</template>
														<template #head(contractDate)="{ label }">
															<div style="height: 55px">
																{{ label }}
															</div>
														</template>
														<template #head(depFunc)="{ label }">
															<b-form-group class="mb-0">
																<template #label>
																	<div class="text-white">
																		{{ label }}
																	</div>
																</template>
																<b-input-group>
																	<b-form-input
																		v-model="filterBy.departmentAndFunctionKeyWord"
																		:placeholder="FormMSG(1899, 'Type to search')"
																		@keyup.enter="handleChangeHeaderFilter"
																		v-b-tooltip.hover
																		:title="FormMSG(2154, 'Press enter for execute search')"
																	/>
																	<b-input-group-append class="cursor-pointer">
																		<b-input-group-text>
																			<component
																				:is="getLucideIcon('Search')"
																				color="#ffffff"
																				:size="16"
																				:stroke-width="2.5"
																				v-if="filterBy.departmentAndFunctionKeyWord.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#FFFFFF"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="handleResetFilterBy('departmentAndFunctionKeyWord')"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-form-group>
														</template>
														<template #head(supplierName)="{ label }">
															<b-form-group class="mb-0">
																<template #label>
																	<div class="text-white">
																		{{ label }}
																	</div>
																</template>
																<b-input-group>
																	<b-form-input
																		v-model="filterBy.productionCompanyName"
																		:placeholder="FormMSG(1899, 'Type to search')"
																		@keyup.enter="handleChangeHeaderFilter"
																		v-b-tooltip.hover
																		:title="FormMSG(2154, 'Press enter for execute search')"
																	/>
																	<b-input-group-append class="cursor-pointer">
																		<b-input-group-text>
																			<component
																				:is="getLucideIcon('Search')"
																				color="#ffffff"
																				:size="16"
																				:stroke-width="2.5"
																				v-if="filterBy.productionCompanyName.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#FFFFFF"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="handleResetFilterBy('productionCompanyName')"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-form-group>
														</template>
														<template #head(projectName)="{ label }">
															<b-form-group class="mb-0">
																<template #label>
																	<div class="text-white">
																		{{ label }}
																	</div>
																</template>
																<b-input-group>
																	<b-form-input
																		v-model="filterBy.projectName"
																		:placeholder="FormMSG(1899, 'Type to search')"
																		@keyup.enter="handleChangeHeaderFilter"
																		v-b-tooltip.hover
																		:title="FormMSG(2154, 'Press enter for execute search')"
																	/>
																	<b-input-group-append class="cursor-pointer">
																		<b-input-group-text>
																			<component
																				:is="getLucideIcon('Search')"
																				color="#ffffff"
																				:size="16"
																				:stroke-width="2.5"
																				v-if="filterBy.projectName.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#FFFFFF"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="handleResetFilterBy('projectName')"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-form-group>
														</template>
														<template #head(option)="{ label }">
															<div style="height: 55px">
																{{ label }}
															</div>
														</template>
														<template #cell(actionSelected)="data">
															<b-form-checkbox
																v-model="data.item.checked"
																size="lg"
																@change="handleChangeItemChecked($event, data.item, data.index)"
															/>
														</template>
														<template #cell(date)="{ item }">
															<div>
																{{ item.date | formatDate('DD/MM/YYYY') }}
															</div>
														</template>
														<template #cell(amount)="{ item }">
															<div>
																{{ item.amount | formatCurrency('DD/MM/YYYY') }}
															</div>
														</template>
														<template #cell(coTwo)="{ item }">
															<div>
																{{ item.coTwo | formatRendKg() }}
															</div>
														</template>
														<template #cell(status)="{ item }">
															<div class="wrap-status">
																<div
																	class="status fw-700"
																	:class="{
																		validated: item.status === 3,
																		'not-submitted': item.status === 0,
																		info: item.status === 1,
																		pending: item.status === 2
																	}"
																>
																	<div class="text-center">
																		{{ getLabelStatus(item) }}
																	</div>
																</div>
															</div>
														</template>
														<template #cell(option)="{ item }">
															<div class="d-flex justify-content-center" style="min-width: 60px">
																<b-dropdown
																	id="dropdown-green-action"
																	no-caret
																	dropleft
																	boundary="window"
																	class="btn-transparent"
																	variant="none"
																	size="sm"
																	lazy
																>
																	<template #button-content>
																		<component :is="getLucideIcon('MoreVertical')" :size="16" />
																	</template>
																	<b-dropdown-item @click="handleClickReview(item)">
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('CheckCircle')" :size="16" />&nbsp;{{
																				FormMSG(259, 'Review')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item>
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('RotateCcw')" :size="16" />&nbsp;{{
																				FormMSG(289, 'Reset status')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item>
																		<span class="text-color-burning-tomato">
																			<component :is="getLucideIcon('Trash2')" :size="16" />&nbsp;{{
																				FormMSG(260, 'Remove')
																			}}
																		</span>
																	</b-dropdown-item>
																</b-dropdown>
															</div>
														</template>
													</b-table>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="6">
													<div class="d-flex">
														<b-button
															size="md"
															variant="custom-outline-gray"
															class="mr-2"
															:disabled="loadingDeleteAction || deleteDisabled"
															@click="handleClickDeleteSelection"
														>
															<b-spinner v-if="loadingDeleteAction" small />
															{{ FormMSG(158, 'Delete') }}
														</b-button>
														<b-dropdown
															id="dropdown-action-selection"
															variant="outline-primary"
															lazy
															:disabled="jobRequestToReview.length === 0 || loadingActionSelection"
														>
															<template #button-content>
																<b-spinner v-if="loadingActionSelection" small />
																{{ FormMSG(569, 'Change selection status') }}
															</template>
															<b-dropdown-item @click="handleClickChangeStatus(0)">
																<div class="d-flex text-color-rhapsody-in-blue">
																	<div class="mr-2">
																		<component :is="getLucideIcon('RotateCcw')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(815, 'Reset selection status') }}
																	</div>
																</div>
															</b-dropdown-item>
															<b-dropdown-item @click="handleClickChangeStatus(1)">
																<div class="d-flex text-color-rhapsody-in-blue">
																	<div class="mr-2">
																		<component :is="getLucideIcon('Send')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(715, 'Mars as PO SENT') }}
																	</div>
																</div>
															</b-dropdown-item>
															<b-dropdown-item @click="handleClickChangeStatus(2)">
																<div class="d-flex text-color-rhapsody-in-blue">
																	<div class="mr-2">
																		<component :is="getLucideIcon('Clock4')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(716, 'Mark as PO CONFIRMED') }}
																	</div>
																</div>
															</b-dropdown-item>
															<b-dropdown-item @click="handleClickChangeStatus(3)">
																<div class="d-flex text-color-rhapsody-in-blue">
																	<div class="mr-2">
																		<component :is="getLucideIcon('Check')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(717, 'Mark as CLOSED') }}
																	</div>
																</div>
															</b-dropdown-item>
														</b-dropdown>
													</div>
												</b-col>
											</b-row>
										</b-card-text>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</error-container>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced.vue';
import moment from 'moment/moment';
import { getJobRequestList, getJobRequest, delJobRequest, getTotalJobRequestList, changeStatusJobRequestList } from '@/cruds/job-request.crud';
import { mapActions, mapGetters } from 'vuex';
import { JOB_REQUEST_STATUS_MENU, FILTER_TYPE } from '@/shared/constants';
import MultiSelectionConfirmDialog from '@/modules/carbonclap/components/green/MultiSelectionConfirmDialog.vue';
import _ from 'lodash';
import BudgetMixin from '@/components/Budget/budget.mixin';

const LIMIT = 50;

export default {
	name: 'JobRequestList',
	components: { FilterBudgetAdvanced, MultiSelectionConfirmDialog },

	mixins: [languageMessages, globalMixin, BudgetMixin],

	data() {
		return {
			error: {},
			jobRequestList: [],
			filterBy: {
				fullNameKeyWord: '',
				departmentAndFunctionKeyWord: '',
				productionCompanyName: '',
				projectName: ''
			},
			resetLoadData: false,
			offset: 0,
			limit: _.cloneDeep(LIMIT),
			alreadyLastData: false,
			loadingActionSelection: false,
			loadingDeleteAction: false,

			stateError: {
				msg: '',
				show: false
			},

			pagination: {
				offset: 0,
				totalRecords: 0
			},
			totalRecord: 0,
			showMultiSelectionConfirmDialog: false,

			selectAll: false,
			jobRequestToReview: [],
			FILTER_TYPE
		};
	},

	watch: {
		$route: {
			handler() {
				setTimeout(() => {
					let tableGreen = this.$refs['table-job-request'].$el;
					tableGreen.removeEventListener('scroll', this.handleScroll);
					tableGreen.addEventListener('scroll', this.handleScroll);
				}, 200);
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		...mapGetters({
			filterBudget: 'global-filter/filterBudget',
			listFilters: 'global-filter/listFilters',
			paginationFilter: 'global-filter/paginationFilter'
		}),

		deleteDisabled() {
			return !this.jobRequestToReview.length > 0;
		},

		filterJobRequest() {
			if (!_.isNil(this.filterBudget.forJobRequest)) {
				const payload = this.filterBudget.forJobRequest;
				const startDate = payload.startDate;
				const endDate = payload.endDate;

				const filterToReturn = this.returnFilter(this.filterBudget.forJobRequest);

				return {
					...filterToReturn,
					startDate,
					endDate
				};
			}

			return {
				startDate: null,
				endDate: null,
				departmentId: -1
			};
		},
		jobRequestFields() {
			let result = [
				{
					key: 'actionSelected',
					label: '',
					class: 'text-center actionSelected',
					sortable: false
				},
				{
					key: 'id',
					label: this.FormMSG(165, 'Request ID'),
					sortable: true
				},
				{
					key: 'requestDate',
					label: this.FormMSG(268, 'Request date'),
					formatter: (value) => {
						return moment(new Date(value.split('T')[0])).format('DD/MM/YYYY');
					},
					sortable: true
				},
				{
					key: 'status',
					label: this.FormMSG(269, 'Status'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(270, 'Full name'),
					sortable: true
				},
				// {
				// 	key: 'contractDate',
				// 	label: this.FormMSG(271, 'Contract date'),
				// 	sortable: true
				// },
				{
					key: 'depFunc',
					label: this.FormMSG(272, 'Department & Function'),
					formatter: (value, key, item) => {
						return `${item.department} - ${item.function}`;
					},
					sortable: true
				},
				{
					key: 'supplierName',
					label: this.FormMSG(273, 'Production company'),
					formatter: (value, key, item) => {
						return value;
					},
					sortable: true
				},
				{
					key: 'projectName',
					label: this.FormMSG(274, 'Project name'),
					formatter: (value, key, item) => {
						return item.projectName;
					},
					sortable: true
				},
				{
					key: 'option',
					label: this.FormMSG(275, 'Option'),
					sortable: true
				}
			];

			return result;
		}
	},

	async created() {
		await this.getJobRequestList();
	},

	async mounted() {
		this.$bus.$on('filter-budget-advanced:change', async (payload) => {
			await this.handleFilterBudgetAdvancedChange(payload);
		});

		this.$bus.$on('filter-budget-advanced:clear-filters', async (payload) => {
			await this.handleFilterBudgetAdvancedChange({});
		});
	},

	destroyed() {
		// shutdown event bus
		this.$bus.$off('filter-budget-advanced:change');
		this.$bus.$off('filter-budget-advanced:clear-filters');
	},

	methods: {
		...mapActions({
			setCurrentJobRequest: 'job-request/setCurrentJobRequest'
		}),

		async handleChangeHeaderFilter() {
			await this.getJobRequestList();
		},

		async handleScroll(event) {
			const scrollTop = event.target.scrollTop;
			const scrollHeight = event.target.scrollHeight;
			const clientHeight = event.target.clientHeight;

			if (event.target.scrollTop) {
				this.resetLoadData = false;
			}
			if (scrollTop + clientHeight >= scrollHeight) {
				if (!this.resetLoadData) {
					if (this.alreadyLastData === false) {
						this.offset += this.limit;
						await this.getGreenTableList(true);
					}
				} else {
					this.resetLoadData = false;
				}
			}
		},

		async handleFilterBudgetAdvancedChange(payload) {
			this.filterActive = payload;
			if (!payload.startDate && !payload.endDate) {
				this.filterActive.startDate = null;
				this.filterActive.endDate = null;
			}
			if (payload.startDate && !payload.endDate) {
				this.filterActive.startDate = payload.startDate;
				this.filterActive.endDate = null;
			}
			if (!payload.startDate && payload.endDate) {
				this.filterActive.startDate = null;
				this.filterActive.endDate = payload.endDate;
			}
			if (payload.startDate && payload.endDate) {
				this.filterActive.startDate = payload.startDate;
				this.filterActive.endDate = payload.endDate;
			}
			if (!payload.userDepartment) {
				this.filterActive.userDepartment = -1;
			}

			this.resetOffsetFilter();
			await this.getJobRequestList();
		},

		resetOffsetFilter() {
			this.offset = 0;
			this.pagination.offset = 0;
			this.jobRequestToReview = [];
			this.resetLoadData = true;
			this.alreadyLastData = false;

			this.$refs['table-job-request'].$el.scrollTo(0, 0);
		},

		async handleClickChangeStatus(status) {
			const action = async () => {
				try {
					this.loadingActionSelection = true;
					let listId = [];

					for (let i = 0; i < this.jobRequestToReview.length; i++) {
						listId.push(+this.jobRequestToReview[i].id);
					}

					if (listId.length > 0) {
						await changeStatusJobRequestList(listId, status);

						this.jobRequestList = this.jobRequestList.map((option) => {
							if (listId.includes(+option.id)) {
								return {
									...option,
									status
								};
							}

							return {
								...option
							};
						});

						this.createToastForMobile(this.FormMSG(98, 'Success'), this.FormMSG(621, 'Status changed successfully!'));
					}

					this.loadingActionSelection = false;
				} catch (e) {
					this.stateError.msg = this.resolveGqlError(e);
					this.stateError.show = true;
					this.loadingActionSelection = false;
				}
			};

			this.confirmModal(action, this.FormMSG(587, 'Are you sure ?'));
		},

		async handleClickDeleteSelection() {
			const action = async () => {
				try {
					let listId = [];
					this.loadingDeleteAction = true;
					for (let i = 0; i < this.jobRequestToReview.length; i++) {
						const element = this.jobRequestToReview[i];
						listId.push(+element.id);
					}

					if (listId.length > 0) {
						await delJobRequest(listId);

						this.jobRequestList = this.jobRequestList.filter((option) => !listId.includes(+option.id));
						this.jobRequestToReview = [];
						this.selectAll = false;
						this.jobRequestList = this.jobRequestList.map((option) => ({
							...option,
							checked: false
						}));

						this.createToastForMobile(this.FormMSG(98, 'Success'), this.FormMSG(252, 'Job requests deleted successfully!'));
					}

					this.loadingDeleteAction = false;
				} catch (e) {
					this.stateError.msg = this.resolveGqlError(e);
					this.stateError.show = true;
					this.loadingDeleteAction = false;
				}
			};

			this.confirmModal(action, this.FormMSG(587, 'Are you sure ?'));
		},

		async handleClickLoadMore() {
			if (this.alreadyLastData === false) {
				this.offset += this.limit;
				await this.getJobRequestList(true, 'containerJobRequestTable');
			}
		},

		handleChangeItemChecked(payload, item, index) {
			let findIndex = -1;
			if (this.jobRequestToReview.length > 0) {
				findIndex = this.jobRequestToReview.findIndex((option) => parseInt(option.id, 10) === parseInt(item.id, 10));
			}

			if (findIndex > -1) {
				if (payload === false) {
					this.jobRequestToReview.splice(findIndex, 1);

					if (this.jobRequestToReview.length === 0) {
						this.selectAll = false;
					}
				}
			} else if (findIndex === -1) {
				if (payload === true) {
					this.jobRequestToReview.push({
						...item,
						indexFromList: index
					});
					this.selectAll = true;
				}
			}
		},

		handleInputSelectAll(value) {
			this.jobRequestToReview = [];

			this.jobRequestList = this.jobRequestList.map((option) => {
				const result = {
					...option,
					checked: value
				};
				if (value) {
					this.jobRequestToReview.push(result);
				}

				return result;
			});

			if (value) {
				if (this.pagination.offset < this.totalRecord) {
					this.showMultiSelectionConfirmDialog = true;
				}
			} else {
			}
		},

		getLabelStatus(item) {
			return this.GetTextFromMenuNumberAndMenuValue(JOB_REQUEST_STATUS_MENU, item.status);
		},

		async getJobRequestList(toPush = false, containerToLoaded = 'containerJobRequestList') {
			const actionForLoader = async () => {
				try {
					this.jobRequestToReview = [];

					let newFilterActive = {
						...this.filterJobRequest,
						fullNameKeyWord: this.filterBy.fullNameKeyWord,
						departmentAndFunctionKeyWord: this.filterBy.departmentAndFunctionKeyWord,
						productionCompanyName: this.filterBy.productionCompanyName,
						projectName: this.filterBy.projectName,
						recordOffset: this.offset,
						recordLimit: this.limit
					};

					this.totalRecord = await getTotalJobRequestList({
						...this.filterJobRequest,
						fullNameKeyWord: this.filterBy.fullNameKeyWord,
						departmentAndFunctionKeyWord: this.filterBy.departmentAndFunctionKeyWord,
						productionCompanyName: this.filterBy.productionCompanyName,
						projectName: this.filterBy.projectName
					});

					if (toPush === false) {
						this.pagination.offset = 0;
						this.setPaginationOffset();
						this.jobRequestList = await getJobRequestList(newFilterActive);
					} else if (toPush === true) {
						this.setPaginationOffset();
						const res = await getJobRequestList(newFilterActive);

						if (res.length > 0) {
							this.jobRequestList = _.concat(this.jobRequestList, res);
						} else {
							if (this.pagination.offset === this.totalRecord) {
								this.alreadyLastData = true;
							}
						}
					}
				} catch (e) {
					this.stateError.msg = this.resolveGqlError(e);
					this.stateError.show = true;
				}
			};

			this.$nextTick(async () => {
				await this.showLoader(actionForLoader, containerToLoaded);
			});
		},
		setPaginationOffset() {
			if (this.totalRecord < LIMIT) {
				this.pagination.offset = this.totalRecord;
			} else {
				if (this.pagination.offset + LIMIT > this.totalRecord) {
					this.pagination.offset = this.totalRecord;
				} else {
					this.pagination.offset += LIMIT;
				}
			}
		},
		async handleClickReview(item) {
			const result = await getJobRequest(item.id);
			this.setCurrentJobRequest(result);
			this.$router.push(`/job-requests/${item.id}`);
		},
		handleResetFilterBy(fieldName) {
			this.filterBy[fieldName] = '';
		},
		handlerFilterBudgetAdvancedHaveFilter(payload) {},
		handleDismissedError() {
			this.stateError = {
				msg: '',
				show: false
			};
		}
	}
};
</script>
