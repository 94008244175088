/**
 * Mixing used for common FLAGS customization for expenses.
 */
import { store } from '@/store';
import _ from 'lodash';
import { getProjectFlags, addUpdateProjectFlagEntity } from '@/cruds/flag.crud';
import { FLAG_TYPE, FLAG_ENTITY_ITEM_TYPE } from '@/shared/constants';

export default {
	name: 'ExpenseFlagsMixin',
	data() {
		return {
			flags: [],
			newValueCustomFlags: []
		};
	},
	computed: {
		canEditExpense() {
			return !!store.canEditExpense();
		}
	},
	methods: {
		async setDefaultCustomFlags(expense) {
			// get flags
			const flagsRecord = await getProjectFlags(FLAG_TYPE.DEFAULT_FLAG);
			this.flags = flagsRecord.customFlags;

			if (_.isNil(expense)) return;

			this.newValueCustomFlags = [];
			this.flags = this.flags.map((flag) => {
				const customFlag = expense.projectFlagItems
					? expense.projectFlagItems.customFlags.filter((item) => !_.isNil(item) && +flag.flagId === +item.flagId)
					: [];
				if (customFlag.length > 0) {
					this.newValueCustomFlags.push({
						flagId: +customFlag[0].flagId,
						item: customFlag[0]
					});
				}
				return {
					...flag,
					customFlagSelected: customFlag.length > 0 ? customFlag[0].id : null
				};
			});
		},
		customFlagChanged(payload, flagId) {
			if (this.newValueCustomFlags.length === 0) {
				const items = this.flags.filter((flag) => +flag.flagId === +flagId)[0].projectFlagItem;
				const item = items.filter((item) => +item.id === +payload)[0];
				this.newValueCustomFlags.push({
					flagId,
					item
				});
			} else {
				const index = this.newValueCustomFlags.findIndex((flag) => flag.flagId === flagId);
				if (index === -1) {
					const items = this.flags.filter((flag) => +flag.flagId === +flagId)[0].projectFlagItem;
					const item = items.filter((item) => +item.id === +payload)[0];
					this.newValueCustomFlags.push({
						flagId,
						item
					});
				} else {
					if (_.isNil(payload)) {
						this.newValueCustomFlags.splice(index, 1);
					} else {
						const items = this.flags.filter((flag) => +flag.flagId === +flagId)[0].projectFlagItem;
						const item = items.filter((item) => +item.id === +payload)[0];
						this.newValueCustomFlags[index].item = item;
					}
				}
			}
		},
		sizeFlagInput(length) {
			let size = 4;

			if (length >= 4) {
				size = 3;
			}

			return size;
		},
		async updateExpenseFlagItems(entityId, vatType = null, taxOptions = null) {
			let flagEntityInputs = [];

			for (const flag of this.newValueCustomFlags) {
				if (!_.isNil(flag.item)) {
					flagEntityInputs.push({
						flagId: +flag.item.flagId,
						value: +flag.item.value,
						entityId: +entityId,
						type: FLAG_ENTITY_ITEM_TYPE.expense,
						factor: +flag.item.factor
					});
				}
			}

			if (!_.isNil(vatType)) {
				const taxItem = taxOptions.filter((tax) => !_.isNil(tax.item) && +vatType === +tax.item.id)[0];

				flagEntityInputs.push({
					flagId: +taxItem.item.flagId,
					value: +taxItem.item.value,
					entityId: +entityId,
					type: FLAG_ENTITY_ITEM_TYPE.expense,
					factor: +taxItem.item.factor
				});
			}

			await addUpdateProjectFlagEntity(+entityId, FLAG_ENTITY_ITEM_TYPE.expense, flagEntityInputs);
		}
	}
};
