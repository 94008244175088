var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(20, "Multiselection"),
        "header-class": "header-class-modal-doc-package",
        size: "lg",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { hidden: _vm.emitEventClose, close: _vm.emitEventClose },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: { variant: "custom-outline-gray" },
                      on: {
                        click: function ($event) {
                          return _vm.handleClickCancelSelection(cancel)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(587, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleClickCancel(cancel)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(46, "Keep current selection")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleClickOk(cancel)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t" + _vm._s(_vm.okCaption) + "\n\t\t\t")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "fs-12 text-color-rhapsody-in-blue" },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          `${_vm.FormMSG(59, "You just selected the")} ${
                            _vm.totalDataNumberSelected
                          } ${_vm.FormMSG(
                            60,
                            "records displayed in the list, while here are in total"
                          )} ${_vm.totalDataNumber} ${_vm.FormMSG(
                            96,
                            "records."
                          )}`
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "fs-12 text-color-rhapsody-in-blue mt-3" },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          `${_vm.FormMSG(
                            101,
                            "Do you want to extend your selection to the"
                          )} ${_vm.totalDataNumber} ${_vm.FormMSG(
                            152,
                            "records ?"
                          )}`
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }